@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.item {
  width: calc(100% / 1);

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportSmall) {
    width: calc(100% / 2);
  }

  @media (--viewportMedium) {
    padding: 4px 0;
  }
  & > span {
    margin: 0px;
    & > label {
      margin: 0;
      padding: 0;
    }
  }
  &.colorItem {
    display: flex;
    align-items: center;
    & > img {
      width: 30px;
      height: 30px;
      border: solid 2px var(--matterColorAnti);
      border-radius: 100px;
      padding: 1px;
    }
    & > span {
      margin-left: 12px;
      & > label {
        margin: 0;
        padding: 0;
        & > span {
          margin: 0;
          padding: 0;
          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}

.categoryContainer {
  display: flex;
  gap: 10px;
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Add padding */
  @media (max-width: 549px) {
    padding: 0;
  }
}

.categoryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  @media (max-width: 549px) {
    justify-content: flex-start;
    gap: 10px;
  }
  @media (--viewportSmall) {
    border: solid 1px var(--colorGrey100);
    border-radius: var(--borderRadiusMedium);
    background-color: var(--colorGrey50);
  }
  & .categoryName {
    padding: 8px 10px;
    font-size: 14px;
    font-weight: var(--fontWeightSemiBold);
    background-color: var(--colorGrey50);
    transition: var(--transitionStyle);
    color: var(--colorGrey600);
    cursor: pointer;
    @media (max-width: 549px) {
      width: auto;
      border-radius: var(--borderRadiusMedium);
      border: solid 1px var(--colorGrey100);
    }

    @media (--viewportSmall) {
      text-align: center;
      width: calc(100% / 4);
      padding: 8px;
      font-size: 13px;
    }
    @media (--viewportMedium) {
      width: calc(100% / 4);
      font-size: 14px;
      padding: 12px 16px;
    }
    @media (--viewportLarge) {
      padding: 14px 20px;
      font-size: 15px;
    }
    @media (--viewportSmall) {
      &:not(:last-child) {
        border-right: solid 1px var(--colorGrey100);
      }
    }
    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: var(--marketplaceColor);
      color: var(--colorWhite);
      transition: var(--transitionStyle);
    }
  }
}
